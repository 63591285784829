import { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';

import classNames from 'classnames';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

import { HOLIDAYS_PER_MONTH, PARTTIME_FACTOR, WORK_HOURS_PER_DAY } from '../../config';
import { countHolidays, countWeekDays, getWorkingHours } from '../../utils';

dayjs.extend(customParseFormat);
dayjs.locale('nl');
dayjs.extend(utc);

export const App = () => {
	const [selectedDate, setSelectedDate] = useState(dayjs.utc());
	const [weekDays, setWeekDays] = useState(0);
	const [workingDays, setWorkingDays] = useState(0);
	const [workingHours, setWorkingHours] = useState(0);
	const [showMonths, setShowMonths] = useState(false);

	useEffect(() => {
		const newWeekDays = countWeekDays({ selectedDate });
		setWeekDays(newWeekDays);
		const newWorkingDays =
			newWeekDays - countHolidays({ holidays: HOLIDAYS_PER_MONTH, selectedDate });
		setWorkingDays(newWorkingDays);
		const newWorkingHours = getWorkingHours(newWorkingDays, PARTTIME_FACTOR);
		setWorkingHours(newWorkingHours);
	}, [selectedDate]);

	const doTransition = () => {
		document.startViewTransition(() =>
			flushSync(() => {
				setShowMonths(!showMonths);
			})
		);
	};

	return (
		<>
			<button
				onClick={() => {
					doTransition();
				}}
				className={classNames('btn-show-months', { 'btn-show-months-open': showMonths })}
			>
				{showMonths ? <>&#10005;</> : <>&#9776;</>}
			</button>
			{showMonths && (
				<nav>
					{Array.from(Array(12).keys()).map(month => (
						<li key={`month-${month}`}>
							<button
								onClick={() => {
									setSelectedDate(dayjs(`2024-${month + 1}-01`));
									doTransition();
								}}
								className={classNames({
									'month-selected': selectedDate.month() === month,
								})}
							>
								{dayjs(`2024-${month + 1}-01`).format('MMMM')}
							</button>
						</li>
					))}
				</nav>
			)}
			<main>
				{workingHours && (
					<div className="hours">
						{Math.ceil(workingHours)
							.toString()
							.split('')
							.map((character, index) => (
								<span key={`hour-${character}-${index}`}>{character}</span>
							))}
					</div>
				)}
			</main>
			<footer>
				<span>
					ma-vr: {weekDays} / {workingDays}
				</span>
				<span>dpm: {(workingHours / WORK_HOURS_PER_DAY).toFixed(1)}</span>
			</footer>
		</>
	);
};
