export const HOLIDAYS_PER_MONTH = {
	2024: {
		januari: 1,
		april: 2,
		mei: 2,
		december: 2,
	},
};

export const WORK_HOURS_PER_DAY = 9;

export const PARTTIME_FACTOR = 0.9;
