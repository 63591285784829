export const countWeekDays = ({ selectedDate }) => {
	const now = selectedDate;
	let workingDays = 0;

	for (let i = 1; i <= now.daysInMonth(); i++) {
		if (now.date(i).day() !== 0 && now.date(i).day() !== 6) workingDays++;
	}
	return workingDays;
};

export const countHolidays = ({ holidays, selectedDate }) => {
	const monthName = selectedDate.format('MMMM');
	return monthName in holidays[selectedDate.year()]
		? holidays[selectedDate.year()][monthName]
		: 0;
};

export const getWorkingHours = (days, factor = 1) => days * factor * 8;
